import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { UserQuery } from './user/user.query';
import { Auth } from 'aws-amplify';
import {GenesystokenService} from './genesystoken.service'
import { requestsService } from './requests/requests.service';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(public user: UserQuery,private apiService: requestsService,private toster:ToastrService,
        private router: Router,public genesysService:GenesystokenService) { 
          
        }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        var userToken='';
        if(this.genesysService.tokenType=='genesys'){
            userToken =this.genesysService.dataToken;
        }
        else{
            userToken =this.user.getToken();
        }
       // const userToken = this.user.getToken();
       
        const modifiedReq = req.clone({
            headers: req.headers.set('Authorization', `${userToken}`).set('region','eu_west_2'),
        });

        return next.handle(modifiedReq).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('API Response:', event);
                    console.log(event?.body?.message)
                   if(event?.body?.message=='Expired JWT'){
                    var message='Session expired, please relogin'
                    // this.toster.warning(message)
                    this.user.currentSession().subscribe(res => {
                        this.refreshToken(res)
                       
                      });
                   }
                }
            }),
            catchError((err: any) => {
                console.log(err);
                if (err instanceof HttpErrorResponse) {
                    console.log(err.status);
                    if (err.status === 401) {
                        this.user.setToken(null);
                        this.router.navigate(['/genesys']);
                    }

                    if (err.status === 500) {
                        this.user.currentSession().subscribe(res => {
                            this.refreshToken(res)
                           
                          });
                        
                       
                        
                        // Auth.currentAuthenticatedUser().catch(res => {
                        //     console.log(res);
                        //     this.user.setToken(null);
                        //     this.router.navigate(['/genesys']);
                        // });
                    }
                }
                console.log("error status",err.status)
                throw new Error(err.status);
            })
        );

    }

    async refreshToken(user1:any){
        const session = await Auth.currentSession();
        //const user = Auth.currentAuthenticatedUser();
        session.getRefreshToken()
       
        const congnitoRefreshToken=session.getRefreshToken();
        var token=congnitoRefreshToken.getToken()
        console.log(token)
    
        this.user.setToken(session.getIdToken().getJwtToken());
     var tokenResponse=  this.user.getToken()
     console.log(tokenResponse)
    
        // window.location.reload();
      //  return await user.refreshSeassion(token).then((d:any)=>{
      //   console.log(d);
      //   this.user.setToken(token);
      //   //localStorage.setItem('profileToken',JSON.stringify(token));
      //  }) 
      }
}