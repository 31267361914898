<div class="verify-user-container">
    <div class="brand-name">
        <a routerLink="/"><img src="/assets/brand-logo.png"></a>
    </div>
    <div class="body-wrapper">
        <div class="card">
            <div class="banner">
                <img src="assets/illustrations/verify-user.png">
            </div>
            <app-heading [title]="'We\'ve sent you Verification Mail'" [size]="20"></app-heading>
            <div class="info">Once you verify your Email Address you'll be able proceed to Dashboard</div>
            <div class="form">
                <form [formGroup]="verifyUser" (submit)="verify($event)">
                    <app-form-input [label]="'Verification code'">
                        <input type="text" formControlName="code">
                    </app-form-input>
                    <div class="btn">
                        <app-button>Verify</app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>