import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss']
})
export class DatesComponent implements OnInit {

  @ViewChild('panelDOM') panelDOM: ElementRef;

  @Input() show = false;
  @Input() title = null;
  @Input() date;
  @Input() allowTime = false;
  @Input() disableInit = false;
  @Input() disableFutureDates = false;
  @Input() disablePastDates = false;
  @Output() change = new EventEmitter<any>();
  selection: string;
  month: string;
  day: string;
  year: number;
  today:number
  monthName: string;
  days = [];
  mode = 0;
  dated:any
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  showPicker = false;
  setTimeForm: FormGroup;
  constructor() {
    const date = new Date();
    this.setTimeForm = new FormGroup({
      timeHr: new FormControl(date.getHours() > 12 ? date.getHours() - 12:date.getHours() == 12 ? date.getHours() - 12 : date.getHours()),
      timeMin: new FormControl(date.getMinutes()),
      timeZone: new FormControl(date.getHours() > 12 ? 12:date.getHours() == 12 ? 12 : 0),
    })
  }

  ngOnInit() {
    const date = new Date();
    this.day = ('0' + date.getDate()).substr(-2);
      this.month = ('0' + (date.getMonth() + 1)).substr(-2);;
    this.year = date.getFullYear();
    var futureDate =new Date(Date.now());
    console.log(futureDate);
    //Initialy 1 put on just after getDate() function to disable the future dates but not just after today date
    var fuDay = ('0' + (futureDate.getDate())).substr(-2);
    this.today = parseInt('' + this.year + this.month + fuDay);
    console.log(this.today)
    this.selection = this.day + '-' + this.month + '-' + this.year;
    this.setMonth();
    if (!this.disableInit) {
      setTimeout(() => this.close());
    }
    var previousDate =new Date(Date.now() - 12096e5);
    console.log(previousDate);
    const lastmonth=('0' + (date.getMonth()))
    var lastDay = ('0' + previousDate.getDate()).substr(-2);
    this.dated = parseInt('' + this.year + lastmonth + lastDay);
    console.log(this.dated,lastDay, this.year +''+ this.month+''+lastDay)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show) {
      this.showPicker = changes.show.currentValue;
    }
    if (changes['date'] && changes.date.currentValue !== this.selection && !changes.date.firstChange) {
      const format = changes.date.currentValue.split('-');
      if (format.length > 0) {
        const old = format[1];
        format[1] = format[0];
        format[0] = old;
      }

      const date = new Date(format);
      this.day = ('0' + date.getDate()).substr(-2);
      this.month = ('0' + (date.getMonth() + 1)).substr(-2);;
      this.year = date.getFullYear();
      this.selection = this.day + '-' + this.month + '-' + this.year;
      this.setMonth();
      // setTimeout(() => this.close());
    }
  }

  setMonth() {
    const days = new Date(this.year, parseInt(this.month), 0).getDate();
    var pos = new Date(this.month + ' ' + 1 + ' ' + this.year).getDay();
    this.days = [];
    if (pos > 0) {
      for (let i = 0; i < pos; i++) {
        this.days.push(0);
      }
    }
    for (let i = 0; i < days; i++) {
      this.days.push(('0' + (i + 1)).substr(-2));
    }
  }

  nextMonth() {
    let month = parseInt(this.month) + 1;
    if (month > 12) {
      month = 1;
      this.year++;
    }
    this.month = ('0' + month).substr(-2);
    this.setMonth();
  }

  prevMonth() {
    let month = parseInt(this.month) - 1;
    if (month < 1) {
      month = 12;
      this.year--;
    }
    this.month = ('0' + month).substr(-2);
    this.setMonth();
  }

  pickDate(date) {
    console.log(parseInt('' + this.year + this.month + date) , this.dated)
    let time = '';
    if (this.disableFutureDates && parseInt('' + this.year + this.month + date) > this.today) {
      console.log(this.disableFutureDates)
      return;
    }
    // if (parseInt('' + this.year + this.month + date) < this.dated) {
    //   console.log(this.disablePastDates)
    //   return;
    // }
    if (this.allowTime) {
      console.log("ALLOW TIME")
            if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)>9) && this.setTimeForm.value.timeMin<10){
              time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
            }
            else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin>9){
              
              console.log(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)
              time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + this.setTimeForm.value.timeMin + ':00';
            }
            else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin<10){
         
              time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
            }
            else{
             
              time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + this.setTimeForm.value.timeMin + ':00';
            }
          
          }

    this.change.emit(date + '-' + this.month + '-' + this.year + time);
    this.showPicker = false;
  }
  

  close(emit = true) {
    let time = '';
    console.log(emit)
    if (this.allowTime) {

      if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)>9) && this.setTimeForm.value.timeMin<10){
        time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
      }
      else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin>9){
        
        console.log(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)
        time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + this.setTimeForm.value.timeMin + ':00';
      }
      else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin<10){
   
        time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
      }
      else{
       
        time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + this.setTimeForm.value.timeMin + ':00';
      }
    
    }
    if (emit) {
      console.log(emit)
      this.change.emit(this.day + '-' + this.month + '-' + this.year + time);
    } else {
      // this.change.emit(this.selection + time);
    }
    this.showPicker = false;
  }
}


