import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';
import {requestsService} from '../services/requests/requests.service'
//import {ApiClientClass} from 'purecloud-platform-client-v2';
import { Config } from '../config';
import {GenesystokenService} from '../services/genesystoken.service'
 import {ReportsdetailsService} from '../services/reportsdetails.service';
import { ToastrService } from 'ngx-toastr';
import { promise } from 'selenium-webdriver';
import { timeStamp } from 'console';
import { setTimeout } from 'timers';
// import { GenesysCloudWebrtcSdk, ISdkConfig } from 'genesys-cloud-webrtc-sdk';


@Component({
  selector: 'app-genesys-setup',
  templateUrl: './genesys-setup.component.html',
  styleUrls: ['./genesys-setup.component.scss']
})
export class GenesysSetupComponent implements OnInit {
  
  genesysUserexists:any;
  ClientGensysUser:any;
  aouthclient:any;
  instance:any;
  // @Output() genesysUserexists = new EventEmitter<any>();
  stepTypes = {
    START: 'START',
    // CUSTOMSETUP: 'CUSTOM SETUP',
    INSTALL: 'INSTALL',
     SUMMARY:'SUMMARY'
  };
  steps = [
    {
      id: this.stepTypes.START,
      name: 'START'
    },
    // {
    //   id:  this.stepTypes.CUSTOMSETUP,
    //   name: 'CUSTOM SETUP'
    // },
    {
      id:  this.stepTypes.INSTALL,
      name: 'INSTALL'
    },
    {
      id:  this.stepTypes.SUMMARY,
      name: 'SUMMARY'
    }
  ];
  username:string="";
  userMe:any;
  activeStep = 0;
  showError: string = null;
  showSuccess = false;
  myData:any;
  platformclient:any;
  pcEnvironment:any;
  pcLanguage:any; 
  clientApp:any=null;
  ClientIdForHome:any; 
  client:any=null; 
  usersApi:any=null;
  apiClient:any=null;
  integrationsApi:any=null;
  productAvailable:boolean=false;
  integrationId:string="";
  authorizationApi:any=null;
  modules:any=null
  oAuthApi:any=null;
  groupsApi:any=null;

  installedData = {};
  IsSetupComplete=false;
  genesysUrl:any;
  urlForDot:any;
  @Input() resion;
  environment:any;
  roleName:any;
  
  constructor(private router: Router,
    private user: UserQuery,private apiService: requestsService,private config:Config,private genesysServie:GenesystokenService,private propService:ReportsdetailsService,private toastr: ToastrService,) {
      
     console.log(window.location.ancestorOrigins[0])
    //  var url =window.location.ancestorOrigins[0]
    //  var afterDot = url.substring(url.indexOf('.'));
    // //  var dot =url.replace(/^(?:[^.]*.*.){3}/g, '')
    //  console.log(afterDot.slice(1))
    //  this.urlForDot=afterDot.slice(1)
    //  this.genesysUrl=afterDot.slice(1)+'/oauth';
    //  console.log(this.genesysUrl)
      this.platformclient= (<any>window).platformClient;
      // console.log(<any>window);
      // // let ClientApp = require('purecloud-client-app-sdk');
      // var ClientApp = (<any>window).purecloud.apps.ClientApp;
      //   var myClientApp = new ClientApp({
      //        pcEnvironmentQueryParam: 'pcEnvironment'
      //   });
      // this.environment= myClientApp.pcEnvironment;
      // console.log(this.environment)
      this.client= this.platformclient.ApiClient.instance;
      console.log(this.client)
      this.usersApi =new this.platformclient.UsersApi();
      this.integrationsApi = new this.platformclient.IntegrationsApi();
      this.authorizationApi= new this.platformclient.AuthorizationApi();
      this.oAuthApi= new this.platformclient.OAuthApi();
      this.groupsApi = new this.platformclient.GroupsApi();
      // console.log(this.config.pcEnvironmentQueryParam)
      this.modules= [
        {roleModule:this.config.provisioningInfo.role,provisioningRoleInfoKey:'Role'}, 
        {groupModule:this.config.provisioningInfo.group,provisioningGroupInfoKey:'Group'}, 
        {appInstanceModule:this.config.provisioningInfo.appinstance,provisioninagAppInstanceInfoKey:'app-instance'}, 
         {OAuthClientModule:this.config.provisioningInfo.oauthclient,provisioningOauthClientInfoKey:'oauth-client'},
        ];
     }

  ngOnInit(): void {
      console.log(this.modules)
     if(this.genesysServie.tokenType ==="genesys")
      {

          this.setDynamicParameters();
          
          console.log(this.platformclient,this.platformclient.ApiClient,this.platformclient.ApiClient.config,this.platformclient.ApiClient.config.environment);
          var apic=this.platformclient.ApiClient
          console.log(apic)
          var conf=apic.config;
          var env1=conf.environment
          console.log(env1);
          
          //  var env=this.platformclient.ApiClient.config.getEnvironment()
          // var resion1=this.platformclient.PureCloudRegionHosts;
          // // console.log(env)
          // this.resion = Object.keys(resion1).find(key => resion1[key] === this.environment);
          // console.log(this.resion)
          // this.apiService.setTest(this.resion)
          // console.log(this.client)
          console.log("current evn" +this.config.defaultPcEnvironment)
          this.client.setEnvironment(this.config.defaultPcEnvironment);
          this.client.setPersistSettings(true,this.config.appName)
          this.genesysServie.dataToken=null
          //const urlParams = new URLSearchParams(window.location.search);
          var queryStringData = {
            response_type : "token",
            client_id : this.config.clientID,
            redirect_uri : "http://localhost:4200/oauth2/callback"
        }
          this.client.loginImplicitGrant(this.config.clientID,this.config.wizardUriBase).then(res=>{
            // alert(res)
            console.log(res);
            this.genesysServie.dataToken = res.accessToken;
          //   this.apiService.getUserdetails().subscribe(s=>{
          //     console.log(s);
          //     if(this.propService.EmailLink !=null){
          //       window.location.href=this.propService.EmailLink
          //     }
          // })
            return this.getUserDetails()
            // this.apiService.getUserdetails().subscribe(s=>{
            //   console.log(s);
            //   if(this.propService.EmailLink !=null){
            //     window.location.hr ef=this.propService.EmailLink
            //   }
            //   else{
                     //this.router.navigateByUrl('/login');
            //   }
            
            //   this.myBrowser()
            // });
      
          }).then(user=>{
            console.log(user)
            this.userMe=user;
            this.username=user.name;
            return this.getIntegrationId();
          }).then(integraion=>{
              console.log(integraion)
              this.integrationId=integraion;
              return this.setPageLanguage();
          }).then(()=>{
              this.setup();
              this.runpageScript()
          })
          .catch((e) => {
            console.error(e);
      
          }); 
      
      }
      
  }
  runpageScript(){
    this.validateProductAvailability()
                .then((isAvailable) => {
                  console.log()
                    if(isAvailable){
                       this.productAvailable=true;
                       this.genesysServie._productAvailable=true;
          
                    }else{
                        this.productAvailable=false;
                        this.genesysServie._productAvailable=false;
                    }
                  return this.isExisting();
                })
                // Check if has an existing installation
                .then((exists) => {
                  this.genesysServie.genesysUserexists=this.myData;
                 this.genesysServie.ClientGensysUser=this.ClientIdForHome
                  // this.genesysUserexists=exists
                    //this  below for loop commented on 28/02/2024  because redirect issue is coming in clarisys 
                for(let i=0;i<this.myData.length;i++){
                    // if(this.myData[i].name==this.config.provisioningInfo.appinstance[0].name){
                      if(this.myData[i].name){
                    this.router.navigateByUrl("/home");
                      this.myBrowser()
                    }
                  
                    else{
                  
                    }
                }
                  
                    // if(exists) {
                    //    this.router.navigateByUrl("/home");
                    
                    //   // this.apiService.getUserdetails().subscribe(s=>{
                    //   //   console.log(s);
                    //   //   if(this.propService.EmailLink !=null){
                    //   //     window.location.href=this.propService.EmailLink
                    //   //     //this.router.navigateByUrl(this.propService.EmailLink)
                    //   //   }
                    //   //   else{
                    //   //     this.router.navigateByUrl('/home');
                    //   //   }
                    //   // });
                    //  // window.location.href = this.config.wizardUriBase;
                    //   // if(!this.userMe.authorization.permissions.includes(this.config.viewPermission)){
                    //   //   window.location.href = './unlicensed.html';
                    //   // } else {
                    //   //     window.location.href = this.config.premiumAppURL;
                    //   // }
                    // } else {
                    //     //view.showContent();
                    //    // resolve();
                    //    //return this.isExisting();
                    // }
                });

  }

  isExisting(){
    let exists = false;

    return this.getInstalledObjects()
    .then((installed) => {
        console.log(installed);
        installed.forEach(item => {
          console.log(item,item.total)
          exists = false;
            // if(item.total && item.total > 0){
            //     // If it's a Genesys Cloud search result
            //     console.log("If it's a Genesys Cloud search result")
            //     exists = false;
            //     return exists;
            // }else{
            //     // if it's just an array
            //     console.log("if it's just an array")
            //     if(item.length >0){
            //       exists=true;
            //       console.log("extist1")
            //     }
            //     else{
            //       exists =false;
            //       console.log("extist2")
            //     }
            //      //item.length > 0 ? true : exists;
            // }
        });

        return exists;
    })
    .catch((e) => console.error(e));
}
getInstalledObjects(){
  let promiseArr = [];
  this.modules.forEach((module) => {
      // if(module.provisioningInfoKey){
        if(module.provisioningRoleInfoKey=='Role'){
          
          //console.log(role);
          promiseArr.push(this.getRoleExisting());
        }
        else if(module.provisioningGroupInfoKey=='Group'){
         // var group= ;
          //console.log(group);
          promiseArr.push(this.getGroupExisting());
        }
        else if(module.provisioninagAppInstanceInfoKey=='app-instance'){
          // var appinstanceexist= ;
          // console.log(appinstanceexist);
          // promiseArr.push(this.getAppInstanceExisting());
        } 
        else if(module.provisioningOauthClientInfoKey=='oauth-client'){
          // var oauthclient= ;
          // console.log(oauthclient);
          // promiseArr.push(this.getOauthExisting());
        } 
       // return promiseArr;
     // }
  });
  return Promise.all(promiseArr);
}

  setPageLanguage(){
    return this.pcLanguage;
    //return new Promise((resolve, reject) => {
        // let fileUri = 
        //     `${this.config.wizardUriBase}assets/languages/${this.pcLanguage}.json`;
        // $.getJSON(fileUri)
        // .done(data => {
        //     Object.keys(data).forEach((key) => {
        //         let els = document.querySelectorAll(`.${key}`);
        //         for(let i = 0; i < els.length; i++){
        //             els.item(i).innerHTML = data[key];
        //         }
        //     })
        //     //resolve();
        // })
        // .fail(xhr => {
        //     console.log('Language file not found.');
        //     //resolve();
        // }); 
        this.pcLanguage=this.pcLanguage;
       // resolve;
    //});
}

  getUserDetails(){
    let opts = {'expand': ['authorization']};
    
    return this.usersApi.getUsersMe(opts);
  }
  getIntegrationId(){
    return new Promise((resolve, reject) => {
        this.integrationsApi.getIntegrationsClientapps({pageSize: 1000})
        .then((data) => {
          
            let instances = data.entities;
            this.myData=data.entities;
            let pa_instance = instances.find(instance => instance.name == this.config.appName);
            if(pa_instance){
                resolve(pa_instance.id);
            }else{
                resolve(null);
            }
        })
        .catch(err => reject(err))
    });
}   

setup(){
  // this.client = pcClient;
  // userMe = user;
  // integrationId = instanceId;
  
  // Use only modules in provisioning info
 this.modules.filter((module) => {
      return Object.keys(this.config.provisioningInfo)
              .includes(module.provisioningInfoKey);
  });
}

validateProductAvailability(){   
  return this.integrationsApi.getIntegrationsType(this.config.appName)    //getIntegrationsTypes({})
  .then((data) => {
      if (data.id === this.config.appName){
          return(true);
      } else {
          return(false);
      }
  });
}
  nextStep() {
      if(this.activeStep==0){
        this.IsSetupComplete=true;
        let creationPromises = [];
        let configurationPromises = [];
        let finalFunctionPromises = [];
        //setTimeout(()=>{
          this.getRoleExisting().then(roles=>{
            this.roleName=roles.entities[0]?.name
            if(roles.entities.length >0){
            }
            else{
              
              creationPromises.push(
                this.roleModuleAdd());
            }
          }).then(res1=>{
            this.getGroupExisting().then(group=>{
              if(group.total >0){
              }
              else{
                creationPromises.push(
                  this.groupModuleAdd());
              }
            })
          }).then(res3=>{
            // this.getOauthExisting().then(ouathClient=>{
            //   console.log(ouathClient)
            //   this.aouthclient=ouathClient[0].name
            //   if(ouathClient.length >1){
            //     console.log("already Create Ouath Client")
            //   }else{
            //     creationPromises.push(
            //       this.ouathClientAdd()
            //       );
            //   }
            // })
          }).then(res2=>{
            // this.getAppInstanceExisting().then(appInstance=>{
            //   console.log(appInstance)
            //   if(appInstance.length >1){
            //     console.log("already Instance add") 
            //   }
            //   else{
            //     this.configureAppInstace(this.userMe.id).then(configInstance=>{
            //       console.log(configInstance)
            //       creationPromises.push(
            //         this.AppInstanceAdd());
            //     })
               
            //   }
            // })
          })
        //},50)
       // setTimeout(()=>{
         
       // },50)
        
     //  setTimeout(()=>{
      
       //},50)
       
     
        console.log(creationPromises)
        this.IsSetupComplete=false;
        if(creationPromises.length>0){
          return Promise.all(creationPromises)
          .then((result) => {
              // Configure all items
              this.modules.forEach((module, i) => {
                  this.installedData[module.provisioningInfoKey] = result[i]; 
              });
  
              this.modules.forEach((module) => {
                console.log(module)
                  configurationPromises.push(
                      module.configure(
                          this.installedData,
                          this.userMe.id
                      )
                  );
              });
  
              return Promise.all(configurationPromises);
          })
          .then(() => {
             
              // Loop through all items with finally 
              Object.keys(this.config.provisioningInfo).forEach(key => {
                  let provisionItems = this.config.provisioningInfo[key];
                  provisionItems.forEach((item) => {
                      if(item.finally){
                          finalFunctionPromises.push(
                              item.finally(this.installedData[key][item.name])
                          );
                      }
                  })
              });
  
              return Promise.all(finalFunctionPromises);
          })
          // Store the installedData in the integration's description
          .then(() => {
              return this.integrationsApi.getIntegrationConfigCurrent(this.integrationId)
              .then((instance) => {
                  let body = instance;
                  let simplifiedData = this.simplifyInstalledData();
                  body.notes = JSON.stringify(simplifiedData);
                  return this.integrationsApi.putIntegrationConfigCurrent(
                              this.integrationId, { body: body });
              })
          })
          .catch((e) => console.error(e));
        }
        
      }
     
    
      this.activeStep++
    
  }
  simplifyInstalledData(){
    let result = {};
    Object.keys(this.installedData).forEach(modKey => {
        let modItems = this.installedData[modKey];
        result[modKey] = {};

        Object.keys(modItems).forEach(itemName => {
            let itemVal = modItems[itemName];
            result[modKey][itemName] = {
                id: itemVal.id,
                name: itemVal.name,
            };
        });
    });
    
    return result;
} 
  saveSprint() {
    this.router.navigateByUrl('/home');
  }
  setDynamicParameters(){
    // Get Query Parameters
    if(this.urlForDot=="euw2.pure.cloud"){
      this.resion="eu-west-2"
    }else if(this.urlForDot=="mypurecloud.ie"){
      this.resion="eu-west-1"
    }else if(this.urlForDot=="mypurecloud.de"){
      this.resion="eu-central-1"
    }
    else if(this.urlForDot=="cac1.pure.cloud"){
      this.resion="ca-central-1"
    }else if(this.urlForDot=="usw2.pure.cloud"){
      this.resion="us-west-2"
    }else if(this.urlForDot=="mypurecloud.com"){
      this.resion="us-east-1"
    }else if(this.urlForDot=="aps1.pure.cloud"){
      this.resion="ap-south-1"
    }
    else if(this.urlForDot=="apne2.pure.cloud"){
      this.resion="ap-northeast-2"
    }else if(this.urlForDot=="mypurecloud.com.au"){
      this.resion="ap-southeast-2"
    }else if(this.urlForDot=="mypurecloud.jp"){
      this.resion="ap-northeast-1"
    }else{
      this.resion=""
    }
     const urlParams = new URLSearchParams(window.location.search);
 
     let tempLanguage = urlParams.get(this.config.languageQueryParam);
     let tempPcEnv = urlParams.get(this.config.genesysCloudEnvironmentQueryParam); 
   
     // Language
     this.pcLanguage = tempLanguage || this.genesysServie.getLanguage ||
                 this.resion;
   
    
     this.genesysServie.setLanguage =this.pcLanguage
     // Environment
     
     this.pcEnvironment = tempPcEnv ||
                     this.genesysServie.getAppName
                      ||
                     this.genesysUrl;

     this.genesysServie.setAppName= this.pcEnvironment;   
   }
   setAppDynamicParameters(url:string){

    var newUrl= url + "?lanTag="+ this.resion;

    newUrl = newUrl + "&environment="+    this.genesysUrl;

    return url
    
  }
    myBrowser() { 
    if( window.navigator.userAgent.indexOf("edge") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
        return 'edge';
    }else if(window.navigator.userAgent.indexOf("Firefox") != -1 ){
      this.toastr.success("For better Result please use Google Chrome")
      return 'Firefox'; 
    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';
    }else if(window.navigator.userAgent.indexOf("Safari") != -1){
      this.toastr.success("For better Result please use Google Chrome")
        return 'Safari';
    }else if(window.navigator.userAgent.indexOf("AppleWebKit") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'AppleWebKit';
    }else if(window.navigator.userAgent.indexOf("edg") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'edge';
    }
    else {
      this.toastr.success("For better Result please use Google Chrome")
       return 'unknown';

    }

  }
  roleModuleAdd(){
    //var roleInstall= this.config.r
    //var data:any=this.configureRole(this.userMe.id);
    let data = this.config.provisioningInfo.role;
    let rolePromises = [];
    let roleData = {}; // Object of "rolename": (Role Object)

    // Create the roles
    
    data.forEach((role) => {
        let roleBody = {
            name: this.config.prefix + role.name,
            description: '',
            permissionPolicies: role.permissionPolicies
        };

        // Assign role to user
        let roleId = null;
        rolePromises.push(
            this.authorizationApi.postAuthorizationRoles(roleBody)
            .then((data) => {
              this.roleName=data.name
                console.log('Created role: ' + role.name);

                roleData[role.name] = data;
            })
            .catch((err) => console.log(err))
        );
    });

    return Promise.all(rolePromises)
    .then(() => roleData);
  }
  groupModuleAdd(){
   // setTimeout(() => {
    let groupPromises = [];
    let groupData = {};
    console.log(this.config.provisioningInfo.group)
    let data = this.config.provisioningInfo.group;
    data.forEach((group) => {
        let groupBody = {
            name: this.config.prefix + group.name,
            description: group.description,
            type: 'official',
            rulesVisible: true,
            visibility: "public"
        };
        console.log(groupBody);

        groupPromises.push(
            this.groupsApi.postGroups(groupBody)
            .then((data) => {
                console.log('Created group: ' + group.name);
                groupData[group.name] = data;
            })
            .catch((err) => console.log(err))
        );
    });

    return Promise.all(groupPromises)
    .then(() => groupData);
  //},50)
  }
  AppInstanceAdd(){
  //  setTimeout (()=>{
      let integrationPromises = [];
      let enableIntegrationPromises = [];
      let integrationsData = {};
      console.log(this.config.provisioningInfo.appinstance)
      let data = this.config.provisioningInfo.appinstance;
      data.forEach((instance) => {
          let integrationBody = {
              body: {
                  integrationType: {
                      id: this.config.appName
                  }
              }
          };
  
          // Rename and add Group Filtering
          integrationPromises.push(
              this.integrationsApi.postIntegrations(integrationBody)
              .then((data) => {
                  console.log("Created instance: " + instance.name);
                  integrationsData[instance.name] = data.id;
                  if(data){
                    this.configureAppInstace(data.id)
                  }
              })
          );
      });
  
      return Promise.all(integrationPromises)
      .then(() => integrationsData);
   // },50)
   
  }

  ouathClientAdd(){
   // setTimeout(()=>{
      let authData = {};

      // Assign employee role to the oauth client because required
      // to have a role id on creation
      return this.authorizationApi.getAuthorizationRoles({
          name: 'employee'
      })
      .then((result) => {
        console.log(result)
          let employeeRole = result.entities[0];
  
          let authPromises = [];
          console.log(this.config.provisioningInfo.oauthclient)
          let data = this.config.provisioningInfo.oauthclient;
          data.forEach((oauth) => {
              let oauthClient = {
                name: this.config.prefix + oauth.name,
                //name: oauth.name,
                description: oauth.description,
                authorizedGrantType: oauth.authorizedGrantType,
                registeredRedirectUri:[oauth.registeredRedirectUri],
                roleIds:[employeeRole.id]
              };
  
              authPromises.push(
                  this.oAuthApi.postOauthClients(oauthClient)
                  .then((data) => {
                      authData[oauth.name] = data;
                      console.log(data);
                      console.log('Created ' + data.name + ' auth client');
                  })
                  .catch((err) => console.log(err))
              );
  
              
          })
  
          return Promise.all(authPromises);
      })
      .then(() => authData);
   // },50)
  
  }
  getRoleExisting(){
    let authOpts = { 
        'name': this.config.prefix + "*", // Wildcard to work like STARTS_WITH 
        'userCount': "false"
    };

    return this.authorizationApi.getAuthorizationRoles(authOpts);
}
getGroupExisting(){
  // Query bodies
  let groupSearchBody = {
      query: [
          {
              fields: ['name'],
              value: this.config.prefix,
              operator: 'OR',
              type: 'STARTS_WITH'
          }
      ]
  };

  return this.groupsApi.postGroupsSearch(groupSearchBody);
}
// getAppInstanceExisting(){
//   let integrationsOpts = {
//       'pageSize': 100
//   };
  
//   return this.integrationsApi.getIntegrations(integrationsOpts)
//   .then((data) => {
//     console.log(data)
//     console.log(data.entities)
//       return(data.entities
//               .filter(entity => {
//                   return entity.integrationType.id ==  this.config.appName 
//                           && entity.name.startsWith(this.config.prefix);
//               }));
//   });  
// }
// getOauthExisting(){
//   return this.oAuthApi.getOauthClients()
//   .then((data) => {
//       console.log('==================================');
//       console.log(data);
//       this.ClientIdForHome=data;
//       return(data.entities
//           .filter(entity => {
//               if(entity.name)
//                   return entity.name.startsWith(this.config.prefix);
//               else
//                   return false;
//           }));
//   });
// }
configureRole(userId){
  // Assign the role to the user
  // Required before you can assign the role to an Auth Client.
  let promiseArr = [];
  let roleData =this.config.provisioningInfo.role;
  console.log(roleData)
  Object.keys(roleData).forEach((roleKey) => {
      
      promiseArr.push(
          this.authorizationApi.putAuthorizationRoleUsersAdd(
              roleData[roleKey].name, 
              [userId]
          )
          .then((data) => {
              console.log('Assigned ' + roleData[roleKey].name + ' to user');
          })
      );
  });
  
  return Promise.all(promiseArr);
}

configureGroup(userId){
  let promiseArr = [];
  let groupData = this.config.provisioningInfo.group;

  Object.keys(groupData).forEach((groupKey) => {
      promiseArr.push(
          this.groupsApi.postGroupMembers(
              groupData[groupKey].id,
              {
                  memberIds: [userId],
                  version: 1
              }
          )
      );
  });

  return Promise.all(promiseArr);
}

configureAppInstace(userId){
  console.log(userId)
  let instanceInstallationData = this.config.provisioningInfo['appinstance'];
  let appInstancesData = this.config.provisioningInfo.appinstance;

  let promisesArr = [];
  console.log(appInstancesData)
  console.log(instanceInstallationData)
  Object.keys(appInstancesData).forEach((instanceKey) => {
    console.log(instanceKey)
      let appInstance = appInstancesData[instanceKey];
      let appInstanceInstall =  instanceInstallationData
                                          .find((a) => a.name == instanceKey);
      console.log(appInstance)
      this.instance=appInstance.name
      let integrationConfig = {
          body: {
              name: appInstance.name,
              version: 1, 
              properties: {
                url: this.setAppDynamicParameters(appInstance.url),
                  sandbox: 'allow-forms,allow-modals,allow-popups,allow-presentation,allow-same-origin,allow-scripts', 
                  displayType: 'standalone',
                  featureCategory: '', 
                  groupFilter: []
              },
              advanced:  {},
              notes: appInstance.notes || `Provisioned by ${this.config.appName} integration`,
              credentials: {}
          }
      };
     
      console.log(integrationConfig.body)
      promisesArr.push(
          this.integrationsApi.putIntegrationConfigCurrent(
            userId, 
              integrationConfig
          )
          .then((data) => {
              console.log('Configured instance: ' + appInstance.name);   

              let opts = {
                  body: {
                      intendedState: 'ENABLED'
                  }
              };

              return this.integrationsApi.patchIntegration(userId, opts)
          })
          .then((data) => console.log('Enabled instance: ' + data.name))
          .catch((err) => console.error(err))
      );
  });

  return Promise.all(promisesArr);
}
configureOuathclient(userId){
  let promiseArr = [];
  let oauthData = this.config.provisioningInfo.oauthclient;

  Object.keys(oauthData).forEach((oauthKey) => {
      let promise = new Promise((resolve, reject) => {
          let oauth = oauthData[oauthKey];
          let oauthInstall = this.config.provisioningInfo['oauth-client']
                              .find((info) => info.name == oauthKey);

          let timer = setInterval(() => {
              this.usersApi.getUsersMe({
                  expand: ['authorization']
              })
              .then((result) => {
                  console.log(result);
                  let userRoleIds = result.authorization.roles.map(u => u.id);
                  let userAssigned = true;

                  // Check if all roles for these client is already assigned
                  // to the user
                  oauthInstall.roles.forEach((r) => {
                      if(!userRoleIds.includes(this.config.provisioningInfo.role[r].id)){
                          userAssigned = false;
                      }
                  });

                  if(userAssigned){
                      clearInterval(timer);

                      this.oAuthApi.putOauthClient(
                          oauthData[oauthKey].id,
                          {
                              name: oauth.name,
                              authorizedGrantType: oauth.authorizedGrantType,
                              roleIds: oauthInstall.roles.map(
                                      (roleName) => this.config.provisioningInfo.role[roleName].id)
                                  .filter(g => g != undefined)
                          }
                      )
                      .then(() => {
                          //resolve();
                      })
                      .catch((e) => reject(e));
                  }
              })
              .catch(e => {
                  clearInterval(timer);

                  console.error(e);
              });
          }, 3000);
      });

      promiseArr.push(promise);
  });

  return Promise.all(promiseArr);
}
}
class SdkError extends Error {
  type: SdkErrorTypes;
  details: any;

  /* inherited */
  name: string;
  message: string;
}

// Available Error types
enum SdkErrorTypes {
  generic = 'generic',
  initialization = 'initialization',
  http = 'http',
  invalid_options = 'invalid_options',
  not_supported = 'not_supported',
  session = 'session',
  media = 'media'
}
